import {CurrencyPipe} from '@angular/common'
import {Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {BehaviorSubject} from 'rxjs'
import {PlanoEmpresa} from 'src/app/model/interfaces/inclusao-vida-popup'
import {CompromissoService} from 'src/app/service/compromisso.service'
import {HttpStatusCode, Titularidade} from 'src/app/utils/enums'
import {DefaultSwalModalComponent} from 'src/app/others-component/default-swal-modal/default-swal-modal.component'
import {MigracaoPlanoPopupSteps} from './migrar-plano-steps.enum'
import {TranslateService} from '@ngx-translate/core'
import {MigrarPlanoFormVidaComponent} from './components/migrar-plano-form-vida/migrar-plano-form-vida.component'
import {MigrarPlanoFormPlanoComponent} from './components/migrar-plano-form-plano/migrar-plano-form-plano.component'
import {MigrarPlanoFormFotoComponent} from './components/migrar-plano-form-foto/migrar-plano-form-foto.component'
import {FileUploadPopup, MigrarPlano} from './migrar-plano.interface'
import {retornarMsgErroPorStatus} from 'src/app/utils/util'
import {ApiService} from 'src/app/service/api.service'

@Component({
	selector: 'app-migrar-plano-popup',
	templateUrl: './migrar-plano-popup.component.html',
	styleUrls: [
		'./migrar-plano-popup.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../vendor/libs/ng-select/ng-select.scss',
		'../../../../vendor/libs/spinkit/spinkit.scss',
		'../../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss'
	],
	providers: [CurrencyPipe]
})
export class MigrarPlanoPopupComponent implements OnInit, AfterViewChecked {
	@ViewChild('swalPlanoMigradoComSucesso') swalPlanoMigradoComSucesso: DefaultSwalModalComponent
	@ViewChild('swalPlanoNaoMigrado') swalPlanoNaoMigrado: DefaultSwalModalComponent
	@ViewChild('swalPlanoMigracaoEmAndamento') swalPlanoMigracaoEmAndamento: DefaultSwalModalComponent
	@ViewChild('migrarPlanoFormVida') migrarPlanoFormVida: MigrarPlanoFormVidaComponent
	@ViewChild('migrarPlanoFormPlano') migrarPlanoFormPlano: MigrarPlanoFormPlanoComponent
	@ViewChild('migrarPlanoFormFoto') migrarPlanoFormFoto: MigrarPlanoFormFotoComponent
	formInformacoesVida: FormGroup
	formMigrarPlano: FormGroup
	migracaoPlanoPopupStepEnum = MigracaoPlanoPopupSteps
	currentStep = new BehaviorSubject<MigracaoPlanoPopupSteps>(
		MigracaoPlanoPopupSteps.STEP_FORM_INFORMACOES_VIDA
	)

	readonly steps = [
		MigracaoPlanoPopupSteps.STEP_FORM_INFORMACOES_VIDA,
		MigracaoPlanoPopupSteps.STEP_SELECAO_PRODUTO,
		MigracaoPlanoPopupSteps.STEP_ENVIO_FOTO
	]

	desabilitarBotaoAvancar: () => {}

	tituloModal = ''
	labelAvancar = ''
	labelVoltar = ''
	cpfMigracaoBeneficiario: string
	idCompromisso: number
	idEmpresa: number
	idEmpresaS4E: number
	loading = false
	listaProdutos: PlanoEmpresa[]
	idPlanoBeneficiario: number
	urlFicha: string
	arquivosEnviados: FileUploadPopup[]
	proxVencimentoFaturaBeneficiario: string

	msgErroMigracaoEmAndamentoSwal =
		'Não é possível realizar esta ação, pois o beneficiário ainda está em processo de migração para o novo plano.'
	msgErroPlanoNaoMigradoSwal =
		'Os dados foram salvos, mas para finalizar a implantação clique no botão Sincronizar Vidas, na tela inicial.'
	constructor(
		private activeModal: NgbActiveModal,
		private compromissoService: CompromissoService,
		public translateService: TranslateService,
		private cdr: ChangeDetectorRef,
		private apiService: ApiService
	) {}

	ngOnInit() {
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges()
	}

	carregarDadosPlanoEmpresaFromFormVidas(dadosPlano: {
		listaProdutos: PlanoEmpresa[]
		idPlanoBeneficiario: number
	}) {
		this.listaProdutos = dadosPlano.listaProdutos
		this.idPlanoBeneficiario = dadosPlano.idPlanoBeneficiario
		this.cpfMigracaoBeneficiario = null
	}

	salvarMigracaoPlano() {
		this.loading = true
		const {valorTitular, valorDependente} = this.listaProdutos.find(
			produto => produto.idPlano === this.formMigrarPlano.controls.idNovoPlano.value
		)
		const valorNovoPlano =
			this.formInformacoesVida.controls.grauParentesco.value === Titularidade.Titular
				? valorTitular
				: valorDependente

		const body: MigrarPlano = {
			valorNovoPlano,
			codigoNovoPlano: this.formMigrarPlano.controls.idNovoPlano.value,
			codigoEmpresa: +this.formInformacoesVida.controls.idEmpresa.value,
			cpfBeneficiario: this.formInformacoesVida.controls.cpf.value,
			idCompromisso: this.idCompromisso,
			urlFichaAnexo: this.migrarPlanoFormFoto.urlFicha
		}

		this.compromissoService
			.migrarPlano(body)
			.then(() => {
				this.swalPlanoMigradoComSucesso.defaultModalSwalOptions.html = `<strong>${this.translateService.instant('vidas.modal_vida_avulsa.reminder')}</strong>: 
				${this.translateService.instant('vidas.modal_vida_avulsa.implanted_migracao_succes_text')} 
				<strong>${this.proxVencimentoFaturaBeneficiario}</strong>.`
				this.swalPlanoMigradoComSucesso.openModal()
			})
			.catch(error => {
				const {status, errorResponse} = error
				if (status === HttpStatusCode.ImATeapot) {
					if (errorResponse?.migracaoEmAndamento) {
						this.swalPlanoMigracaoEmAndamento.defaultModalSwalOptions.text = `${this.msgErroMigracaoEmAndamentoSwal}`
						return this.swalPlanoMigracaoEmAndamento.openModal()
					}
					this.swalPlanoNaoMigrado.defaultModalSwalOptions.text = `${this.msgErroPlanoNaoMigradoSwal} - \n - Erro: ${error.error?.message}`
					return this.swalPlanoNaoMigrado.openModal()
				}
				this.apiService.errorMessageShow(retornarMsgErroPorStatus(error))
			})
			.finally(() => {
				this.loading = false
				this.activeModal.close()
			})
	}

	avancarStep(step?: MigracaoPlanoPopupSteps) {
		this.guardarDadosStepAtual()
		if (!step) {
			this.currentStep.next(this.currentStep.getValue() + 1)
		} else {
			this.currentStep.next(step)
		}
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	voltarStep() {
		this.guardarDadosStepAtual()
		this.currentStep.next(this.currentStep.getValue() - 1)
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	configurarLabelsEValidacaoStep(step: MigracaoPlanoPopupSteps) {
		switch (step) {
			case MigracaoPlanoPopupSteps.STEP_FORM_INFORMACOES_VIDA:
				this.tituloModal = 'Migrar de plano'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'
				this.desabilitarBotaoAvancar = () =>
					(!this.migrarPlanoFormVida && !this.migrarPlanoFormVida?.beneficiarioEncontrado) ||
					this.migrarPlanoFormVida?.formInformacoesVida?.invalid ||
					!this.migrarPlanoFormVida?.beneficiarioEncontrado ||
					!this.migrarPlanoFormVida?.beneficiarioEncontrado
				break

			case MigracaoPlanoPopupSteps.STEP_SELECAO_PRODUTO:
				this.tituloModal = 'Migrar de plano'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'
				this.desabilitarBotaoAvancar = () => this.migrarPlanoFormPlano?.formMigrarPlano?.invalid
				break

			case MigracaoPlanoPopupSteps.STEP_ENVIO_FOTO:
				this.tituloModal = 'Migrar de plano'
				this.labelAvancar = 'Finalizar migração'
				this.labelVoltar = 'Voltar'
				this.desabilitarBotaoAvancar = () =>
					!this.migrarPlanoFormFoto?.arquivosEnviados?.length || !this.migrarPlanoFormFoto?.urlFicha
				break
		}
	}

	guardarDadosStepAtual() {
		this.formInformacoesVida =
			this.formInformacoesVida || this.migrarPlanoFormVida?.formInformacoesVida
		this.formMigrarPlano = this.formMigrarPlano || this.migrarPlanoFormPlano?.formMigrarPlano
		this.arquivosEnviados = this.migrarPlanoFormFoto?.arquivosEnviados || this.arquivosEnviados
		this.urlFicha = this.migrarPlanoFormFoto?.urlFicha || this.urlFicha
		this.proxVencimentoFaturaBeneficiario =
			this.migrarPlanoFormVida?.proxVencimentoFaturaBeneficiario ||
			this.proxVencimentoFaturaBeneficiario
	}

	fecharSwalPlanoMigradoComSucessoOuNao() {
		this.avancarStep()
	}

	dismiss() {
		this.activeModal.dismiss()
	}
}
