<div class="form-column mb-2 box-send-file">
	<div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
		<div class="mt-4 d-flex flex-column justify-content-center align-items-center">
			<p
				class="custom-font-size d-flex flex-column justify-content-center align-items-center box-send-file-tx">
				{{ 'vidas.detail.envie_foto_ficha' | translate }}
				<br />
				<strong>
					{{ vidasNome }}
				</strong>
			</p>
		</div>
		<div class="media-body ml-3">
			<div ngbDropdown class="d-inline-flex">
				<button type="button" class="btn btn-dark btn-lg box-send-file-btn" ngbDropdownToggle>
					{{ 'vidas.detail.enviar_foto' | translate }}
				</button>
				<div ngbDropdownMenu>
					<a class="dropdown-item" (click)="openWebcam()">{{
						'vidas.detail.tirar_foto' | translate
					}}</a>
					<a class="dropdown-item" (click)="openDragdrop()">{{
						'vidas.detail.escolher_arquivo' | translate
					}}</a>
				</div>
			</div>
		</div>

		<div class="table-responsive margin-table-arquivos" *ngIf="arquivosEnviados.length">
			<table class="table">
				<thead>
					<tr>
						<th class="font-weight-bold">{{ 'vidas.detail.nome' | translate }}</th>
						<th class="font-weight-bold">{{ 'vidas.detail.tamanho' | translate }}</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let arquivo of arquivosEnviados">
						<td>{{ arquivo.filename }}</td>
						<td>{{ arquivo.size | formatarBytes }}</td>
						<td>
							<div class="cursor-pointer" (click)="visualizarArquivo(arquivo)">
								<img src="../../../../../../assets/icons/view.svg" />
							</div>
						</td>
						<td>
							<a style="cursor: pointer" aria-hidden="true" (click)="removerArquivo(arquivo)">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
										fill="#222222" />
								</svg>
							</a>
						</td>
					</tr>
					<tr>
						<td><br /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
