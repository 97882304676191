import {Injectable} from '@angular/core'
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'

import {InclusaoVidasPopup} from 'src/app/mfe/popup/inclusao-vidas-popup/inclusao-vidas-popup.component'
import {MigracaoPlanoPopupInfo} from 'src/app/model/interfaces/migracao-plano'

import {MigrarPlanoPopupComponent} from 'src/app/mfe/components/migrar-plano-popup/migrar-plano-popup.component'

import {
	IInclusaoVidaContinuaPopup,
	IInclusaoVidaDependentePopup,
	IInclusaoVidaExistentePopup,
	IInclusaoVidaPopup
} from '../../model/interfaces/inclusao-vida-popup'
import {OptionsUpload} from '../../model/interfaces/options-upload'
import {UploadPopupComponent} from '../../others-component/upload-popup/upload-popup.component'
import {VidasService} from '../vidas.service'
import {InclusaoVidaAvulsaPopupComponent} from 'src/app/mfe/popup/inclusao-vida-avulsa-popup/inclusao-vida-avulsa-popup.component'
import {InclusaoVidaPopupSteps} from 'src/app/mfe/popup/inclusao-vida-avulsa-popup/inclusao-vida-popup-enum'

@Injectable({
	providedIn: 'root'
})
export class CustomModalService {
	constructor(
		private ngbModal: NgbModal,
		private vidasService: VidasService
	) {}

	openInclusaoVidasPopup(
		body: IInclusaoVidaPopup,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void
	) {
		const {nomeEmpresa, compromisso, listaProdutos, empresa} = body

		const modal = this.ngbModal.open(InclusaoVidasPopup, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.compromisso = compromisso
		modal.componentInstance.state.list_produtos = listaProdutos
		modal.componentInstance.state.empresa = empresa

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			}
		)
	}

	openInclusaoDependente(
		body: IInclusaoVidaDependentePopup,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void
	) {
		const {nomeEmpresa, compromisso, listaProdutos, empresa, infoTitular, tipoVida} = body

		const modal = this.ngbModal.open(InclusaoVidasPopup, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.compromisso = compromisso
		modal.componentInstance.state.list_produtos = listaProdutos
		modal.componentInstance.state.empresa = empresa
		modal.componentInstance.infoTitular = infoTitular
		modal.componentInstance.tipoVida = tipoVida

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			}
		)
	}

	openVidaExistentePopup(
		body: IInclusaoVidaExistentePopup,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void
	) {
		const {nomeEmpresa, compromisso, listaProdutos, empresa, existente, id} = body

		const modal = this.ngbModal.open(InclusaoVidasPopup, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.compromisso = compromisso
		modal.componentInstance.state.list_produtos = listaProdutos
		modal.componentInstance.state.empresa = empresa
		modal.componentInstance.existente = existente
		modal.componentInstance.id = id

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			}
		)
	}

	openUploadPopup(
		optionsUpload: OptionsUpload,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void
	) {
		const modal = this.ngbModal.open(UploadPopupComponent, options)
		modal.componentInstance.setOptionsUpload(optionsUpload)

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			}
		)
	}

	openInclusaoVida(
		modalBody: IInclusaoVidaContinuaPopup,
		options,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void
	) {
		const modal = this.ngbModal.open(InclusaoVidaAvulsaPopupComponent, options)
		modal.componentInstance.initialSetp = InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA
		if (modalBody) {
			modal.componentInstance.dadosInclusaoVidas = {
				...modalBody,
				...(modalBody.idEmpresa !== 0 ? {empresaFieldDisabled: true} : {})
			}
		}

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			}
		)
	}

	openMigrarPlano(
		modalBody: MigracaoPlanoPopupInfo,
		options: NgbModalOptions,
		cbOnClose?: () => void
	) {
		const modal = this.ngbModal.open(MigrarPlanoPopupComponent, options)
		if (modalBody) {
			modal.componentInstance.idEmpresa = modalBody.idEmpresa
			modal.componentInstance.idEmpresaS4E = modalBody.idEmpresaS4E
			modal.componentInstance.idCompromisso = modalBody.idCompromisso
			modal.componentInstance.cpfMigracaoBeneficiario = modalBody.cpfBeneficiario
		}

		modal.result.then(
			_ => {
				if (cbOnClose) {
					cbOnClose()
				}
			},
			_ => {
				if (cbOnClose) {
					cbOnClose()
				}
			}
		)
	}
}
