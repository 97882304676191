export class ArquivoBeneficiario {
	nome: string
	status: string
	data: Date
}

export class Plano {
	id: number
	nome: string
	valorTitular: number
	valorDependente: number
}

export class Beneficiario {
	codigoBeneficiario: number
	nome: string
	cpf: string
	codigoAssociado: number
	beneficios: string[]
	dataAdesao: string
	vinculo: string
	plano: string
	preco: string
	proximaFatura: boolean
	informacoesInclusao: string
	dataNascimento: string
	nomeMae: string
	sexo: string
	nomeFormatado: string
	idEmpresa: number
	idEmpresaS4e: number
	grauParentesco: string
	idPlano: number
	cpfTitular: string
	anexo: string
	dependentes: Beneficiario[]
	email: string
	telefonecelular: string
	telefonefixo: string
	matricula: string
	tipoVida: string
	precoDependente: string
	valorPlano: string
	anexoData: string
	anexoNome: string
	anexoStatus: string
	titularVinculado: string
	empresaVinculada: string
	signedLinkAnexo: string

	constructor(data: any) {		
		this.codigoBeneficiario = data.codigoBeneficiario
		this.nome = data.nome
		this.cpf = data.cpf
		this.codigoAssociado = data.codigoAssociado
		this.beneficios = data.beneficios
		this.dataAdesao = data.dataAdesao
		this.vinculo = data.vinculo
		this.plano = data.plano
		this.preco = data.preco
		this.proximaFatura = data.proximaFatura
		this.informacoesInclusao = data.informacoesInclusao
		this.dataNascimento = data.dataNascimento
		this.nomeMae = data.nomeMae
		this.sexo = data.sexo
		this.nomeFormatado = data.nomeFormatado
		this.idEmpresa = data.idEmpresa
		this.idEmpresaS4e = data.idEmpresaS4e
		this.grauParentesco = data.grauParentesco
		this.idPlano = data.idPlano
		this.cpfTitular = data.cpfTitular
		this.anexo = data.anexo
		this.dependentes = data.dependentes
		this.email = data.email
		this.telefonecelular = data.telefonecelular
		this.telefonefixo = data.telefonefixo
		this.matricula = data.matricula
		this.tipoVida = data.tipoVida
		this.precoDependente = data.precoDependente
		this.anexoData = data.anexoData
		this.anexoNome = data.anexoNome
		this.anexoStatus = data.anexoStatus
		this.titularVinculado = data.titularNome
		this.empresaVinculada = data.empresaVinculada
		this.valorPlano = data.valorPlano
		this.signedLinkAnexo = data.signedLinkAnexo
	}
}
