import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core'
import {
	NgbDropdown,
	NgbDropdownItem,
	NgbDropdownMenu,
	NgbDropdownToggle
} from '@ng-bootstrap/ng-bootstrap'
import {AgibenButtonStyle} from 'src/app/model/interfaces/aginben-button-style'

export interface MenuButtonOption {
	label: string
	onClick: () => void
}
@Component({
	selector: 'agi-button',
	templateUrl: './agi-button.component.html',
	styleUrls: [
		'./agi-button.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
	encapsulation: ViewEncapsulation.None,
	providers: [NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle]
})
export class AgiButtonComponent implements OnInit {
	@Input() label: string
	@Input() disabled = false
	@Input() type = 'button'
	@Input() loading = false

	@Input() cssClass: AgibenButtonStyle = 'PRIMARY'

	@Output() onClick = new EventEmitter()

	@Input() isMenuButton!: boolean
	@Input() menuButtonOptions: MenuButtonOption[] = []
	@Input() placement: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'

	constructor() {}

	ngOnInit(): void {}

	click() {
		if (!this.disabled) {
			this.onClick.emit()
		}
	}
}
