import {Injectable} from '@angular/core'
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpHeaders
} from '@angular/common/http'
import {Observable, from} from 'rxjs'
import {LocalStorage, StorageMap} from '@ngx-pwa/local-storage'
import {Login} from 'src/app/model/login'
import {ApiService} from '../api.service'
import {Router} from '@angular/router'
import {environment} from 'src/environments/environment'

@Injectable()
export class Interceptor implements HttpInterceptor {
	login: Login

	constructor(private router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | null {
		return from(
			this.handle(request, next).catch(err => {
				if (err.status === 401) {
					localStorage.clear()
					this.router.navigate(['/login/authentication/login-v2'])
					return null
				}
				throw err
			})
		)
	}

	async handle(request: HttpRequest<any>, next: HttpHandler) {
		const loginInfo = localStorage.getItem('login')
		const baseUrl = request.url
		if (loginInfo && !request.params.get('dontIntercept')) {
			const data = JSON.parse(localStorage.getItem('login'))
			const headers = new HttpHeaders({
				'X-Access-Token': !request.headers['X-Access-Token']
					? data.token
					: request.headers['X-Access-Token'],
				Authorization: !request.headers['Authorization']
					? `Bearer ${data.token}`
					: request.headers['Authorization']
			})

			if (baseUrl.includes(environment.API_URL_NEST)) {
				headers.append('Referrer-Policy', 'no-referrer-when-downgrade')
			}

			const cloneReq = request.clone({
				headers
			})
			return await next.handle(cloneReq).toPromise()
		} else {
			const cloneReq = request.clone({setHeaders: {}})
			return await next.handle(cloneReq).toPromise()
		}
	}
}
