export enum Dicionario {
	CLIENTE = 5,
	PLANO = 7,
	QUADRO_AVISO = 17,

	CENTRAL_RELATORIO = 40,
	CONTA_DE_USUARIO = 50,
	GRUPOS_PERMISSOES = 51,
	GRUPO = 52,
	DICIONARIO = 53,
	AUDITORIA = 54,
	CONFIGURACAO_SISTEMA = 55,
	RELATORIO_CADASTRAL = 56,
	OPERADORA = 57,
	TELEFONE = 58,
	EMPRESA = 59,
	FORMA_PAGAMENTO = 60,
	PLANO_REGIAO = 61,
	CAMPANHA = 62,
	PLANO_CAMPANHA = 63,
	MODELOEMAILMARKETING = 64,
	CONTA_CAMPANHA = 65,
	REGIAO_CAMPANHA = 66,
	PLATAFORMA = 67,
	COMISSAO = 68,
	ESTADO = 69,
	CIDADE = 70,
	VENDA = 71,
	TRANSACAO = 72,
	CADASTRO_VENDEDOR = 73,
	BUSCA_DE_BENEFICIARIO = 74,
	MEUS_RELATORIOS = 75,
	SEGUNDA_VIA_DE_BOLETO = 76,
	REDE_CREDENCIADA = 77,
	PAINEL_DE_VENDAS = 78,
	SINDICATO = 79,
	BUSCA_DE_BENEFICIARIO_LIVRE = 80,
	BUSCA_POR_CORRETOR = 81,
	BUSCA_POR_CLIENTE_PJ = 82,
	HISTORICO_TRATATIVA = 83,
	SALES_EMPRESA = 84,
	SALES_ADESAO = 85,
	CALENDARIO = 86,
	CONTATO = 87,
	ATIVIDADE = 88,
	COMPROMISSO = 89,
	SALES_CLIENTE = 90,
	LEAD = 91,
	SALES_AUTORIZACOES = 92,
	SALES_PESQUISA_GERAL = 93,
	SALES_GESTAO_DE_CARTEIRAS = 94,
	SALES_DESPESAS = 95,
	CCT = 96,
	CCT_PARAMETRIZACAO = 97,
	GESTAO_AGENDA = 98,
	ASSINATURAS = 99,	
	VIDAS = 101,
	GESTAO_EMPRESAS = 102,
	CCT_ACT_VISAO_PRESIDENTE = 103,
}
