import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {Beneficiario} from '../model/Beneficiario'
import {VidasFiltroDTO} from '../model/vidas/vidas-filtro-dto'
import {Lives, LivesKpis} from '../vidas/vidas-list/interfaces'
import {AuthService} from './auth/auth.service'
import {
	DadosCpfResult,
	DadosImportadosBeneficiario
} from '../mfe/popup/inclusao-vida-avulsa-popup/model/dados-inclusao-vidas'
import {SexoDescricao} from '../utils/enums'

@Injectable({
	providedIn: 'root'
})
export class VidasService {
	urlServerAPINest: any

	constructor(
		private http: HttpClient,
		private authService: AuthService
	) {
		this.urlServerAPINest = environment.API_URL_NEST
	}

	async calcularKpisVidasPorFarm(idEmpresa?: number, idCompromisso?: number) {
		let url = `${this.urlServerAPINest}vidas/calcular-kpis-vidas-empresa-usuario`
		if (idEmpresa) {
			url += `?idEmpresa=${idEmpresa}`
		}

		if (idCompromisso) {
			url += `${idEmpresa ? '&idCompromisso=' + idCompromisso : '?idCompromisso=' + idCompromisso}`
		}
		return await this.http.get<LivesKpis>(url).toPromise()
	}

	async listarVidasPorFarm(body: VidasFiltroDTO) {
		return await this.http
			.post<Lives>(`${this.urlServerAPINest}vidas/listar-vidas-empresa`, body)
			.toPromise()
	}

	async getDadosBeneficiario(id: number): Promise<Beneficiario> {
		return new Beneficiario(
			await this.http.get(`${this.urlServerAPINest}vidas/beneficiario/${id}`).toPromise()
		)
	}

	uploadFicha(folder: any, fileName: string, file: File) {
		const headers: any = this.authService.getHeadersNest()

		const urlAPI = `${this.urlServerAPINest}vidas/upload/ficha`

		const formData = new FormData()
		formData.append('fileName', fileName)
		formData.append('folder', folder)
		formData.append('ficha', file, file.name)
		delete headers['Content-Type']

		return this.http.post<any>(urlAPI, formData, {
			headers
		})
	}

	async buscarDadosPorCPF(cpf: string): Promise<DadosImportadosBeneficiario> {
		try {
			const cpfLimpo = cpf.replace(/[^\d]/g, '')

			const headers: any = this.authService.getHeadersNest()

			const response = await this.http
				.get<DadosCpfResult>(
					`${this.urlServerAPINest}cliente_compromissos/higienizacao/${cpfLimpo}`,
					{
						headers
					}
				)
				.toPromise()

			if (response.resultado) {
				const email = response.resultado.emails?.[0]?.email || ''

				const celularValido = response.resultado.telefones.find(telefone => telefone.numero.length > 8)
				let telefone = ''
				if(celularValido) {
					telefone = `${celularValido.ddd}${celularValido.numero}`
				}

				let telefonefixo = ''
				const telefoneValido = response.resultado.telefones.find(telefone => telefone.numero.length === 8)
				if(telefoneValido) {
					telefonefixo = `${telefoneValido.ddd}${telefoneValido.numero}`
				}

				const dadosFormatados = {
					nome: response.resultado.nome,
					nomeMae: response.resultado.nomeMae,
					dataNascimento: new Date(response.resultado.dataNascimento),
					sexo: {
						value: response.resultado.sexo,
						descricao:
							response.resultado.sexo === 'M' ? SexoDescricao.MASCULINO : SexoDescricao.FEMININO
					},
					telefone,
					telefonefixo,
					email,
					cpf: response.resultado.cpf
				}

				return dadosFormatados
			}
		} catch (error) {
			throw error
		}
	}
}
