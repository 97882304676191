import {GrauParentesco, Sexo, SexoDescricao, TipoTitularInclusaoVidas} from 'src/app/utils/enums'

export const OpcoesTipoVida = [
	{value: TipoTitularInclusaoVidas.TITULAR, descricao: 'Titular'},
	{value: TipoTitularInclusaoVidas.DEPENDENTE, descricao: 'Dependente'}
]

export const OpcoesTipoSexo = [
	{value: Sexo.MASCULINO, descricao: SexoDescricao.MASCULINO},
	{value: Sexo.FEMININO, descricao: SexoDescricao.FEMININO}
]

export const OpcoesGrauParentesco = [
	{value: GrauParentesco.CONJUGE_OU_COMPANHEIRO, descricao: 'CONJUGE/COMPANHEIRO'},
	{value: GrauParentesco.FILHO, descricao: 'FILHO(A)'},
	{value: GrauParentesco.ENTEADO, descricao: 'ENTEADO(A)'},
	{value: GrauParentesco.PAI_OU_MAE, descricao: 'PAI/MAE'},
	{value: GrauParentesco.AGREGADOS_OU_OUTROS, descricao: 'AGREGADOS/OUTROS'}
]

export const patternCaracteresEspeciaiseEspaco = new RegExp(/\.|\-|\(|\)|\s/g)
