import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2'
import Swal, {SweetAlertIcon, SweetAlertOptions} from 'sweetalert2'

const DEFAULT_CONFIRM_BUTTON =
	'btn btn-lg btn-primary swal-button-offset-padding font-weight-semibold'
const DEFAULT_CANCEL_BUTTON =
	'btn btn-lg btn-outline-dark swal-button-offset-padding font-weight-semibold'
const DEFAULT_ICON = 'swal-custom-primary'
const DEFAULT_TITLE = 'swal-custom-primary'
const DEFAULT_ACTIONS = 'swal-custom-primary'
const DEFAULT_CLOSE_BUTTON = 'swal-custom-primary'
const DEFAULT_CONTENT = 'swal-custom-primary'

@Component({
	selector: 'default-swal',
	templateUrl: './default-swal-modal.component.html',
	styleUrls: [],
})
export class DefaultSwalModalComponent implements OnInit {
	@Input() title: string
	@Input() text?: string
	@Input() html?: string
	@Input() confirmButtonText = 'Entendi'
	@Input() cancelButtonText?: string
	@Input() icon?: SweetAlertIcon = 'success'
	@Input() reverseButtons?: boolean
	@Input() showCloseButton = true
	@Input() allowOutsideClick = false

	@Input() confirmButtonClass?: string = DEFAULT_CONFIRM_BUTTON
	@Input() cancelButtonClass?: string = DEFAULT_CANCEL_BUTTON
	@Input() iconClass?: string = DEFAULT_ICON
	@Input() titleClass?: string = DEFAULT_TITLE
	@Input() actionsClass?: string = DEFAULT_ACTIONS
	@Input() closeButtonClass?: string = DEFAULT_CLOSE_BUTTON
	@Input() contentClass?:string = DEFAULT_CONTENT

	@Output() confirmCallback: EventEmitter<boolean> = new EventEmitter<boolean>()
	@Output() cancelCallback: EventEmitter<Swal.DismissReason> =
		new EventEmitter<Swal.DismissReason>()

	@ViewChild('defaultModalSwal')
	defaultModalSwal: SwalComponent
	defaultModalSwalOptions: SweetAlertOptions

	constructor() {}

	openModalPromise() {
		this.defaultModalSwal.swalOptions = {
			...this.defaultModalSwal,
			...this.defaultModalSwalOptions,
		}

		return this.defaultModalSwal.fire()
	}

	openModal() {
		this.defaultModalSwal.swalOptions = {
			...this.defaultModalSwal,
			...this.defaultModalSwalOptions,
		}

		this.defaultModalSwal.fire().then(confirm => {
			if (confirm.value) {
				return this.confirmCallback.emit(confirm.value)
			}

			if (confirm.dismiss) {
				return this.cancelCallback.emit(confirm.dismiss)
			}
		})
	}

	ngOnInit(): void {
		this.defaultModalSwalOptions = this.setDefaultModalSwalOptions()
	}

	setDefaultModalSwalOptions(): SweetAlertOptions {
		return {
			title: this.title,
			confirmButtonText: this.confirmButtonText,
			showCancelButton: !!this.cancelButtonText,
			cancelButtonText: this.cancelButtonText,
			icon: this.icon,
			reverseButtons: this.reverseButtons,
			showCloseButton: this.showCloseButton,
			text: this.text,
			allowOutsideClick: this.allowOutsideClick,
			html: this.html,

			customClass: {
				confirmButton: this.confirmButtonClass,
				cancelButton: this.cancelButtonClass,
				icon: this.iconClass,
				title: this.titleClass,
				actions: this.actionsClass,
				closeButton: this.closeButtonClass,
				content: this.contentClass
			},
		}
	}
}
