import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {BehaviorSubject, Subscription} from 'rxjs'
import {patternCaracteresEspeciaiseEspaco} from 'src/app/model/vidas/vidas.enum'
import {CompromissoService} from 'src/app/service/compromisso.service'
import {VidasService} from 'src/app/service/vidas.service'
import {AGE_LIMIT, PLANOS_KIDS} from 'src/app/utils/constants'
import {TipoTitularInclusaoVidas} from 'src/app/utils/enums'
import {calcularIdade, formatarData, retornarMsgErroPorStatus} from 'src/app/utils/util'
import {InclusaoVidaNestDTO} from '../../../model/cliente-compromisso/inclusao-vida-nest.dto'
import {Dicionario} from '../../../model/dicionario'
import {PlanoEmpresa} from '../../../model/interfaces/inclusao-vida-popup'
import {Login} from '../../../model/login'
import {ApiService} from '../../../service/api.service'
import {DefaultSwalModalComponent} from '../../../others-component/default-swal-modal/default-swal-modal.component'
import {InclusaoVidaAvulsaFormFotoComponent} from './components/inclusao-vida-avulsa-form-foto/inclusao-vida-avulsa-form-foto.component'
import {InclusaoVidaAvulsaFormPlanoComponent} from './components/inclusao-vida-avulsa-form-plano/inclusao-vida-avulsa-form-plano.component'
import {InclusaoVidaAvulsaFormVidaSegundaComponent} from './components/inclusao-vida-avulsa-form-vida-segunda/inclusao-vida-avulsa-form-vida-segunda.component'
import {InclusaoVidaAvulsaFormVidaComponent} from './components/inclusao-vida-avulsa-form-vida/inclusao-vida-avulsa-form-vida.component'
import {InclusaoVidaAvulsaNovaInclusaoComponent} from './components/inclusao-vida-avulsa-nova-inclusao/inclusao-vida-avulsa-nova-inclusao.component'
import {InclusaoVidaPopupStepList, InclusaoVidaPopupSteps} from './inclusao-vida-popup-enum'
import {DadosInclusaoVidas} from './model/dados-inclusao-vidas'
import {TranslateService} from '@ngx-translate/core'

@Component({
	selector: 'inclusao-vidas-avulsa-popup',
	templateUrl: './inclusao-vida-avulsa-popup.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../vendor/libs/ng-select/ng-select.scss',
		'./inclusao-vida-avulsa-popup.component.scss',
		'../../../../vendor/libs/spinkit/spinkit.scss',
		'../../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss'
	]
})
export class InclusaoVidaAvulsaPopupComponent
	implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
	constructor(
		private apiService: ApiService,
		private activeModal: NgbActiveModal,
		public compromissoService: CompromissoService,
		private cdr: ChangeDetectorRef,
		private vidasService: VidasService,
		private translateService: TranslateService
	) {}

	steps = InclusaoVidaPopupStepList
	inclusaoVidaPopupStepEnum = InclusaoVidaPopupSteps
	initialSetp: InclusaoVidaPopupSteps
	currentStep = new BehaviorSubject<InclusaoVidaPopupSteps>(
		InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA
	)
	@ViewChild('swalVidaImplantadaComSucesso') swalVidaImplantadaComSucesso: DefaultSwalModalComponent
	@ViewChild('swalVidasNaoImplantadas') swalVidasNaoImplantadas: DefaultSwalModalComponent
	@ViewChild('swalImportarDadosCPF') swalImportarDadosCPF: DefaultSwalModalComponent
	@ViewChild('inclusaoVidaAvulsaFormVida')
	inclusaoVidaAvulsaFormVida: InclusaoVidaAvulsaFormVidaComponent
	@ViewChild('inclusaoVidaAvulsaFormPlano')
	inclusaoVidaAvulsaFormPlano: InclusaoVidaAvulsaFormPlanoComponent
	@ViewChild('inclusaoVidaAvulsaFormFoto')
	inclusaoVidaAvulsaFormFoto: InclusaoVidaAvulsaFormFotoComponent
	@ViewChild('inclusaoVidaAvulsaNovoFormFoto')
	inclusaoVidaAvulsaNovoFormFoto: InclusaoVidaAvulsaFormFotoComponent
	@ViewChild('inclusaoVidaAvulsaNovaInclusao')
	inclusaoVidaAvulsaNovaInclusao: InclusaoVidaAvulsaNovaInclusaoComponent
	@ViewChild('inclusaoVidaAvulsaFormVidaSegunda')
	inclusaoVidaAvulsaFormVidaSegunda: InclusaoVidaAvulsaFormVidaSegundaComponent
	@ViewChild('inclusaoVidaAvulsaFormPlanoSegundo')
	inclusaoVidaAvulsaFormPlanoSegundo: InclusaoVidaAvulsaFormPlanoComponent
	formPrimeiraInclusao: FormGroup
	formSelecaoProdutos: FormGroup
	formSegundaInclusao: FormGroup
	formSegundaSelecaoProdutos: FormGroup
	desabilitarBotaoAvancar: () => {}

	subscriptions: Subscription[] = []
	desabilitarBotaoAvancarPorHigienizacao!: boolean
	usouHigienizacao!: boolean

	user: Login
	loading = false
	tituloModal = ''
	labelAvancar = ''
	labelVoltar = ''
	removerVida = false
	addBeneficiary: InclusaoVidaNestDTO
	tipoVidaEnum = TipoTitularInclusaoVidas
	modalFromCompromisso = false

	dadosInclusaoVidas: DadosInclusaoVidas = new DadosInclusaoVidas()

	private cpfAtual: string

	ngOnInit(): void {
		this.modalFromCompromisso = this.dadosInclusaoVidas.idCompromisso > 0
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
		this.apiService.getUser().then((res: Login) => {
			this.user = res
			this.verificarPermissaoExclusao()
		})

		this.verificarFluxoInclusao()
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges()
	}

	ngAfterViewInit(): void {
		this.subscriptions.push(
			this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao.valueChanges.subscribe(_ => {
				if (this.usouHigienizacao) {
					this.desabilitarBotaoAvancarPorHigienizacao =
						this.inclusaoVidaAvulsaFormVida.formPrimeiraInclusao.invalid
				}
			})
		)
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) {
			sub.unsubscribe()
		}
	}

	verificarPermissaoExclusao() {
		if (this.user.temPermissao(Dicionario.SALES_CLIENTE, 'D')) {
			this.removerVida = true
		}
	}

	async avancarStep(step?: InclusaoVidaPopupSteps) {
		if (
			this.currentStep.getValue() === InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA &&
			this.verificarNecessidadeImportarDadosCPF()
		) {
			return
		}

		this.guardarDadosStepAtual()
		if (!step) {
			this.currentStep.next(this.currentStep.getValue() + 1)
		} else {
			this.currentStep.next(step)
		}
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
		this.carregarPlano()
	}

	voltarStep() {
		this.guardarDadosStepAtual()
		this.currentStep.next(this.currentStep.getValue() - 1)
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	verificarNecessidadeImportarDadosCPF(): boolean {
		const form = this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao

		const cpfField = form?.get('vida_cpf')
		if (
			cpfField?.valid &&
			form?.invalid &&
			!this.inclusaoVidaAvulsaFormVida?.beneficiarioJaCadastrado
		) {
			this.cpfAtual = cpfField.value

			this.swalImportarDadosCPF.openModal()
			return true
		}

		return false
	}

	guardarDadosStepAtual() {
		this.formPrimeiraInclusao =
			this.formPrimeiraInclusao ?? this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao

		this.formSelecaoProdutos =
			this.formSelecaoProdutos ?? this.inclusaoVidaAvulsaFormPlano?.formSelecaoProdutos

		this.formSegundaInclusao =
			this.formSegundaInclusao ?? this.inclusaoVidaAvulsaFormVidaSegunda?.formSegundaInclusao

		this.formSegundaSelecaoProdutos =
			this.formSegundaSelecaoProdutos ??
			this.inclusaoVidaAvulsaFormPlanoSegundo?.formSelecaoProdutos

		this.dadosInclusaoVidas.idEmpresa =
			this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idEmpresa ||
			this.dadosInclusaoVidas.idEmpresa

		this.dadosInclusaoVidas.idEmpresaS4E =
			this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idEmpresaS4E ||
			this.dadosInclusaoVidas.idEmpresaS4E

		this.dadosInclusaoVidas.nomeEmpresa =
			this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.nomeEmpresa ||
			this.dadosInclusaoVidas.nomeEmpresa

		this.dadosInclusaoVidas.idCompromisso =
			this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idCompromisso ||
			this.dadosInclusaoVidas.idCompromisso

		this.dadosInclusaoVidas.cpfTitular =
			this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.cpfTitular ||
			this.dadosInclusaoVidas.cpfTitular

		this.dadosInclusaoVidas.arquivosEnviados =
			this.inclusaoVidaAvulsaNovoFormFoto?.arquivosEnviados ||
			this.inclusaoVidaAvulsaFormFoto?.arquivosEnviados ||
			this.dadosInclusaoVidas.arquivosEnviados

		this.dadosInclusaoVidas.urlFicha =
			this.inclusaoVidaAvulsaNovoFormFoto?.urlFicha ||
			this.inclusaoVidaAvulsaFormFoto?.urlFicha ||
			this.dadosInclusaoVidas.urlFicha

		this.dadosInclusaoVidas.nomeTitular =
			this.formSegundaInclusao?.get('vida_nome')?.value ||
			this.formPrimeiraInclusao?.get('vida_nome')?.value
	}

	configurarLabelsEValidacaoStep(step: InclusaoVidaPopupSteps) {
		if (this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE) {
			this.tituloModal = 'Incluir dependente'
		} else if (this.modalFromCompromisso) {
			this.tituloModal = 'Incluir vida'
		} else {
			this.tituloModal = 'Incluir vida avulsa'
		}

		this.labelAvancar = 'Avançar'
		this.labelVoltar = 'Voltar'
		switch (step) {
			case InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA:
				this.desabilitarBotaoAvancar = () =>
					!this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao?.get('vida_cpf')?.valid ||
					this.inclusaoVidaAvulsaFormVida?.buscandoCPF ||
					this.inclusaoVidaAvulsaFormVida?.buscandoEmpresa ||
					!this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idEmpresa ||
					(this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.tipoVida ===
						TipoTitularInclusaoVidas.TITULAR &&
						!this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao?.get('vida_empresa')?.value) ||
					this.desabilitarBotaoAvancarPorHigienizacao
				break

			case InclusaoVidaPopupSteps.STEP_ENVIO_FOTO:
			case InclusaoVidaPopupSteps.STEP_NOVA_ENVIO_FOTO:
				this.labelAvancar =
					this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE
						? 'Finalizar inclusão'
						: 'Avançar'

				const nomeVida =
					this.formSegundaInclusao?.get('vida_nome')?.value ||
					this.formPrimeiraInclusao?.get('vida_nome')?.value
				this.dadosInclusaoVidas.nomeArquivo = `${this.dadosInclusaoVidas.idCompromisso}-${nomeVida}`

				if (step === InclusaoVidaPopupSteps.STEP_NOVA_ENVIO_FOTO) {
					this.desabilitarBotaoAvancar = () =>
						!this.inclusaoVidaAvulsaNovoFormFoto?.arquivosEnviados?.length ||
						!this.inclusaoVidaAvulsaNovoFormFoto?.urlFicha
				} else {
					this.desabilitarBotaoAvancar = () =>
						!this.inclusaoVidaAvulsaFormFoto?.arquivosEnviados?.length ||
						!this.inclusaoVidaAvulsaFormFoto?.urlFicha
				}
				break
			case InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA:
				this.desabilitarBotaoAvancar = () =>
					this.inclusaoVidaAvulsaFormVidaSegunda?.formSegundaInclusao?.invalid ||
					this.inclusaoVidaAvulsaFormVidaSegunda?.buscandoCPF ||
					this.inclusaoVidaAvulsaFormVidaSegunda?.buscandoEmpresa ||
					!this.inclusaoVidaAvulsaFormVidaSegunda?.dadosInclusaoVidas.idEmpresa ||
					this.inclusaoVidaAvulsaFormVidaSegunda?.beneficiarioJaCadastrado
				break
			case InclusaoVidaPopupSteps.STEP_SELECAO_PRODUTO:
			case InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO:
				this.desabilitarBotaoAvancar = () =>
					(this.currentStep.getValue() === InclusaoVidaPopupSteps.STEP_SELECAO_PRODUTO
						? this.inclusaoVidaAvulsaFormPlano?.formSelecaoProdutos?.invalid
						: this.inclusaoVidaAvulsaFormPlanoSegundo?.formSelecaoProdutos?.invalid) || this.loading
				break
		}
	}

	dismiss() {
		this.activeModal.dismiss()
	}

	removeExtraSpaces(beneficiaryData = {}, listToFix: string[] = []) {
		const dataFixed = {}
		listToFix.map(key => {
			if (beneficiaryData[key]) {
				dataFixed[key] = beneficiaryData[key].replace(/ {2,}/g, ' ').trim()
			}
		})
		return dataFixed
	}

	async salvarInclusaoVida() {
		this.guardarDadosStepAtual()
		this.loading = true
		let valorPlano = 0

		let formInclusaoVida: FormGroup
		let formSelecaoProdutos: FormGroup
		if (
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE &&
			this.dadosInclusaoVidas.isDependenteTitular === true
		) {
			formInclusaoVida = this.formSegundaInclusao
			formSelecaoProdutos = this.formSegundaSelecaoProdutos
		} else {
			formInclusaoVida = this.formPrimeiraInclusao
			formSelecaoProdutos = this.formSelecaoProdutos
		}

		formInclusaoVida.get('cli_cpf_titular').setValue(this.dadosInclusaoVidas.cpfTitular)
		formInclusaoVida.get('vida_idcompromisso').setValue(this.dadosInclusaoVidas.idCompromisso)
		if (formSelecaoProdutos) {
			const idProdutoSelecionado = formSelecaoProdutos.get('cco_id_produto_s4')?.value
			if (idProdutoSelecionado) {
				const produtoSelecionado: PlanoEmpresa = this.dadosInclusaoVidas.listaProdutos.find(
					(produto: PlanoEmpresa) => produto.idPlano === Number(idProdutoSelecionado)
				)
				const grauParentescoSelecionado = formInclusaoVida.get('cli_grauparentesco')?.value
				if (grauParentescoSelecionado.value === TipoTitularInclusaoVidas.TITULAR) {
					valorPlano = produtoSelecionado.valorTitular
				} else {
					valorPlano = produtoSelecionado.valorDependente
				}
			}
		}

		const {
			vida_tipo,
			vida_nome,
			vida_cpf,
			vida_nomemae,
			vida_nascimento,
			vida_sexo,
			vida_telefone,
			vida_telefonefixo,
			vida_email,
			vida_idcompromisso,
			vida_matricula,
			cli_grauparentesco,
			fk_idtitular,
			cli_codigoassociados4e,
			cli_cpf_titular
		} = formInclusaoVida.getRawValue()

		const listToFix = ['cli_nome', 'cli_nomemae', 'cli_matricula', 'cli_email']
		const {cco_id_produto_s4} = formSelecaoProdutos.getRawValue()

		this.addBeneficiary = {
			cli_nome: vida_nome,
			cli_cpf: vida_cpf.replace(patternCaracteresEspeciaiseEspaco, ''),
			cli_nomemae: vida_nomemae,
			cli_datanascimento: formatarData(vida_nascimento),
			cli_sexo: vida_sexo.value,
			cli_telefonecelular: vida_telefone.replace(patternCaracteresEspeciaiseEspaco, ''),
			cli_telefonefixo: vida_telefonefixo?.replace(patternCaracteresEspeciaiseEspaco, ''),
			cli_email: vida_email,
			cli_grauparentesco: cli_grauparentesco.value,
			cli_matricula: vida_matricula,
			cli_idplano: cco_id_produto_s4,
			fk_idtitular,
			cli_tipo: vida_tipo.value,
			cli_status: 'A',
			cli_titular: vida_tipo.descricao === 'Titular',
			fk_idempresa: this.dadosInclusaoVidas.idEmpresa,
			cli_enviar_para_s4E: true,
			cli_cod_empresa_s4E: this.dadosInclusaoVidas.idEmpresaS4E,
			cli_codigoassociados4e:
				this.dadosInclusaoVidas.codAssociadoS4E ?? cli_codigoassociados4e?.value,
			cli_cpf_titular,
			clienteCompromisso: {
				fk_idprodutoempresa: null,
				cco_id_produto_s4,
				fk_idcompromisso: this.dadosInclusaoVidas.idCompromisso,
				cco_valor_produto: valorPlano
			},
			cli_anexos: this.dadosInclusaoVidas.urlFicha
		}
		const beneficiaryFixed = this.removeExtraSpaces(this.addBeneficiary, listToFix)
		this.addBeneficiary = {...this.addBeneficiary, ...beneficiaryFixed}

		try {
			const {cliente, clienteS4E, ocorreuErroS4e} =
				await this.compromissoService.inserirVidaNestNovo(this.addBeneficiary)

			this.dadosInclusaoVidas.tipoVida = vida_tipo?.value || this.dadosInclusaoVidas.tipoVida
			if (vida_tipo?.value === this.tipoVidaEnum.TITULAR) {
				this.dadosInclusaoVidas.nomeTitular = vida_nome
				this.dadosInclusaoVidas.idTitular = cliente.pk_idcliente
			}
			if (clienteS4E && !ocorreuErroS4e) {
				const {titular, dependentes} = clienteS4E
				this.dadosInclusaoVidas.codAssociadoS4E =
					titular?.associado?.id ||
					dependentes[0]?.idAssociado ||
					this.dadosInclusaoVidas.codAssociadoS4E
				this.swalVidaImplantadaComSucesso.defaultModalSwalOptions.html = `<strong>${this.translateService.instant('vidas.modal_vida_avulsa.reminder')}</strong>: 
				${this.translateService.instant('vidas.modal_vida_avulsa.implanted_vida_succes_text')} 
				<strong>${this.dadosInclusaoVidas.proxVencimentoFaturaBeneficiario}</strong>.`

				this.swalVidaImplantadaComSucesso.openModal()
			} else {
				this.swalVidasNaoImplantadas.openModal()
			}
		} catch (error) {
			this.apiService.showToast(null, retornarMsgErroPorStatus(error), 'error')
		} finally {
			this.loading = false
		}
	}

	async verificarFluxoInclusao() {
		if (
			this.initialSetp === InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA &&
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE
		) {
			this.currentStep.next(InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA)
		} else if (
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.TITULAR ||
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE
		) {
			this.currentStep.next(InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO)
		}
	}

	finalizarInclusao() {
		this.activeModal.close(this.addBeneficiary)
	}

	novaInclusaoDependente() {
		this.dadosInclusaoVidas.tipoVida = TipoTitularInclusaoVidas.DEPENDENTE
		this.dadosInclusaoVidas.isDependenteTitular = true
		this.dadosInclusaoVidas.urlFicha = ''
		this.dadosInclusaoVidas.arquivosEnviados = []
		this.dadosInclusaoVidas.nomeArquivo = ''
		this.dadosInclusaoVidas.nomeTitular = ''
		this.formSegundaInclusao = null
		this.formSegundaSelecaoProdutos = null
		this.avancarStep(InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA)
	}

	fecharSwalVidaImplantadaComSucessoOuNao() {
		if (this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE) {
			if (this.dadosInclusaoVidas.isDependenteTitular) {
				this.currentStep.next(InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO)
			} else {
				this.finalizarInclusao()
			}
		} else {
			this.avancarStep()
		}
	}

	carregarPlanoFromFormVidas(listaProdutos: PlanoEmpresa[]) {
		this.dadosInclusaoVidas.listaProdutos = listaProdutos
		const dataNascimento = this.formPrimeiraInclusao?.get('vida_nascimento')?.value

		if (!dataNascimento) {
			return
		}

		const idade = calcularIdade(new Date(dataNascimento))

		if (idade > AGE_LIMIT.KIDS_AGE) {
			this.dadosInclusaoVidas.listaProdutos = this.dadosInclusaoVidas.listaProdutos.filter(
				produto => !Object.values(PLANOS_KIDS).includes(produto.idPlano)
			)
		}
	}

	carregarPlano() {
		this.carregarPlanoFromFormVidas(this.dadosInclusaoVidas.listaProdutos)
	}

	importarDadosCPF() {
		if (this.cpfAtual) {
			this.usouHigienizacao = true
			this.loading = true
			this.vidasService
				.buscarDadosPorCPF(this.cpfAtual)
				.then(dadosImportados => {
					if (this.inclusaoVidaAvulsaFormVida) {
						this.inclusaoVidaAvulsaFormVida.preencherDadosImportados(dadosImportados)
						this.desabilitarBotaoAvancarPorHigienizacao =
							this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao.invalid
					}
				})
				.catch(error => {
					this.apiService.showToast(null, 'Erro ao importar dados do CPF', 'error')
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
