<form [formGroup]="formInformacoesVida">
	<div class="mt-0">
		<div class="form-row mt-0 mb-1">
			<h5 class="card-title">{{ 'vidas.modal_migrar.dados_gerais' | translate }}</h5>
		</div>
		<div class="form-row mt-2 pt-2">
			<div class="col pl-0">
				<agisales-input
					id="cpf"
					formControlName="cpf"
					placeholder="{{ 'vidas.detail.select_opcao' | translate }}"
					mask="CPF_CNPJ"
					label="CPF *"
					[hasIconSuffix]="true"
					(OnSearch)="buscarBeneficiarioCadastradoS4E()"
					(OnClear)="resetFormInformacoesVida()"
					ngDefaultControl>
				</agisales-input>

				<div
					*ngIf="
						getFormInformacoesVida.cpf?.touched && getFormInformacoesVida.cpf.errors?.cpfInvalid
					"
					class="mt-2 error">
					CPF {{ 'vidas.modal_vida_avulsa.invalido' | translate }}
				</div>

				<div *ngIf="buscandoCPF" class="mt-2 warning">
					{{ 'vidas.modal_vida_avulsa.verifying_cpf' | translate }}...
				</div>
				<div *ngIf="!beneficiarioEncontradoSearch && !buscandoCPF" class="mt-2 error">
					{{ 'vidas.modal_vida_avulsa.beneficiario_notfound' | translate }}
				</div>
			</div>
		</div>

		<div *ngIf="exibirForm">
			<div class="form-row mt-2" style="text-transform: capitalize">
				<div class="col">
					<agisales-input
						id="empresaVinculada"
						*ngIf="!isDependente"
						placeholder="{{ 'vidas.placeholders.empresa_vinculada' | translate }}"
						formControlName="empresaVinculada"
						label="{{ 'vidas.detail.migrar_plano_empresa_vinculada' | translate }}"
						ngDefaultControl>
					</agisales-input>

					<agisales-input
						id="titularVinculado"
						*ngIf="isDependente"
						placeholder="{{ 'vidas.placeholders.titular_vinculado' | translate }}"
						formControlName="titularVinculado"
						label="{{ 'vidas.detail.titular_vinculado' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col-6">
					<agisales-input
						id="tipoVida"
						placeholder="{{ 'vidas.placeholders.tipo_vida' | translate }}"
						formControlName="tipoVida"
						label="{{ 'vidas.detail.tipo_vida' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
				<div class="col-6" *ngIf="isDependente">
					<agisales-input
						id="grauParentesco"
						formControlName="grauParentesco"
						placeholder="{{ 'vidas.detail.select_opcao' | translate }}"
						label="{{ 'vidas.detail.grau_parentesco' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
				<div class="col-6" *ngIf="!isDependente">
					<agisales-input
						id="matricula"
						formControlName="matricula"
						placeholder="{{ 'vidas.placeholders.informe_matricula_titular' | translate }}"
						label="{{ 'vidas.detail.matricula' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col">
					<agisales-input
						id="nome"
						formControlName="nome"
						placeholder="{{ 'vidas.placeholders.insira_nome_completo' | translate }}"
						label="{{ 'vidas.detail.nome_completo' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col">
					<agisales-input
						id="nomeMae"
						formControlName="nomeMae"
						placeholder="{{ 'vidas.placeholders.insira_nome_mae' | translate }}"
						label="{{ 'vidas.detail.nome_completo_mae' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col-6">
					<agisales-input
						mask="d0/M0/0000"
						id="dataNascimento"
						formControlName="dataNascimento"
						placeholder="Ex: 01/01/2000"
						label="{{ 'vidas.detail.data_nascimento' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>

				<div class="col-6">
					<agisales-input
						id="sexo"
						name="sexo"
						placeholder="{{ 'vidas.placeholders.insira_sexo' | translate }}"
						formControlName="sexo"
						label="{{ 'vidas.detail.sexo' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col-6">
					<agisales-input
						id="telefone"
						formControlName="telefone"
						mask="(00)00000-0000"
						placeholder="{{ 'vidas.placeholders.insira_telefone' | translate }}"
						label="{{ 'vidas.detail.telefone' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>

				<div class="col-6">
					<agisales-input
						id="telefonefixo"
						formControlName="telefonefixo"
						placeholder="(00)0000-0000"
						mask="(00)0000-0000"
						label="{{ 'vidas.detail.telefonefixo' | translate }}"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col-6">
					<agisales-input
						id="email"
						formControlName="email"
						placeholder="{{ 'vidas.placeholders.insira_email' | translate }}"
						label="E-mail"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="form-group col">
				<label class="form-label mb-1 font-weight-normal"
					>* {{ 'vidas.detail.campo_obrigatorio' | translate }}</label
				>
			</div>
		</div>
	</div>
</form>
