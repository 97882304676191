import {CurrencyPipe} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {TranslateService} from '@ngx-translate/core'
import {UploadPopupMfeComponent} from 'src/app/mfe/popup/upload-popup/upload-popup.component'
import {OptionsUpload} from 'src/app/model/interfaces/options-upload'
import {TypeFile} from 'src/app/others-component/upload-popup/type-file'
import {FileUploadPopup} from '../../migrar-plano.interface'
import {VidasService} from 'src/app/service/vidas.service'
import {viewFileBase64} from 'src/app/utils/util'

@Component({
	selector: 'app-migrar-plano-form-foto',
	templateUrl: './migrar-plano-form-foto.component.html',
	styleUrls: ['./migrar-plano-form-foto.component.scss'],
	providers: [CurrencyPipe]
})
export class MigrarPlanoFormFotoComponent implements OnInit {
	loading = false
	uploadImagemRealizado = false
	imagemEnviada!: string
	@Input() urlFicha = ''
	@Input() arquivosEnviados: FileUploadPopup[] = []
	@Input() vidasNome = ''
	@Input() nomeArquivo = ''

	constructor(
		private ngbModal: NgbModal,
		public translateService: TranslateService,
		public vidasService: VidasService
	) {}

	ngOnInit() {
		this.arquivosEnviados = this.arquivosEnviados || []
		this.urlFicha = this.urlFicha || null
	}

	removerArquivo(item) {
		const index = this.arquivosEnviados.findIndex(arquivo => arquivo === item)
		if (index !== -1) {
			this.arquivosEnviados.splice(index, 1)
		}
	}

	visualizarArquivo(item) {
		viewFileBase64(this.imagemEnviada)
	}

	openWebcam() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: true,
			aspectRatio: 4 / 4,
			showWebcam: true
		}

		this.openUploadPopup(optionsUpload)
	}

	openUploadPopup(optionsUpload: OptionsUpload) {
		const options: NgbModalOptions = {windowClass: 'modal-lg animate'}

		const modal = this.ngbModal.open(UploadPopupMfeComponent, options)
		modal.componentInstance.setOptionsUpload(optionsUpload)
		modal.componentInstance.uploadFileCb = (folder, filename, file) =>
			this.vidasService.uploadFicha(folder, filename, file)

		modal.result.then(result => {
			if (result && result.dataURL) {
				this.arquivosEnviados = [{filename: optionsUpload.fileName, size: result?.size}]
				this.imagemEnviada = result.dataURL
				this.urlFicha = `${result.caminhoArquivo}`
				this.uploadImagemRealizado = true
			}
		})
	}

	openDragdrop() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: true,
			aspectRatio: 4 / 4,
			showDragdrop: true
		}
		this.openUploadPopup(optionsUpload)
	}
}
