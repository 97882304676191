<div class="mb-4"
  [ngClass]="{'tema-clin': beneficio?.tipoProvedor === enumTipoProvedor.CLIN, 'tema-melhorai': beneficio?.tipoProvedor === enumTipoProvedor.MELHORAI, 'tema-agiben': beneficio?.tipoProvedor === enumTipoProvedor.AGIBEN}">

  <div class="d-flex flex-direction-row align-items-center card-beneficio-header">
    <div class="card card-logo-img-beneficio">
      <div class="card-body p-4">
        <img class="logo-img-beneficio" [src]="beneficio?.iconeUrl">
      </div>
    </div>

    <div class="ml-4 mb-4 header-title-container">
      {{beneficio?.titulo}}
    </div>
  </div>

  <div class="card d-lg-flex d-none flex-row card-beneficio-body justify-content-between">

    <div class="d-flex w-100 justify-content-start flex-column">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex justify-content-start flex-column w-75">
          <div class="d-flex justify-content-start flex-row border-bottom pb-4">
            <button *ngFor="let topico of beneficio?.topicos;let i = index"
              class="btn rounded-pill btn-pill-custom btn-outline-default m-2 flex-shrink-1 flex-wrap"
              [ngClass]="{'active': topico.ativo}" (click)="ativarSelecao(topico)"
              type="button">{{topico.titulo}}</button>

          </div>
          <div *ngIf="topicoSelecionado" class="conteudo-topico pt-3" [innerHTML]="topicoSelecionado.conteudo"></div>
          <div *ngIf="linkDinamico && !beneficio?.conteudoFooter" class="beneficio-card-footer"><a [href]="linkDinamico"
              target="_blank" (click)="clickEmLinkDinamico(topico)"><strong>Baixe a CCT</strong></a>
            </div>
        </div>

        <div class="d-inline beneficio-ilustracao-container">
          <img class="beneficio-ilustracao" [src]="beneficio?.ilustracaoUrl">
        </div>
      </div>

      <div *ngIf="beneficio?.conteudoFooter" class="beneficio-card-footer mt-3" [innerHTML]="beneficio?.conteudoFooter">
      </div>
    </div>
  </div>

  <div class="card d-lg-none d-flex flex-column card-beneficio-body">
    <div class="d-flex flex-column justify-content-between ">
      <div class="d-flex justify-content-start flex-column">
        <div class="d-flex justify-content-start flex-row border-bottom pb-4 flex-wrap">
          <button *ngFor="let topico of beneficio?.topicos;let i = index"
            class="btn rounded-pill btn-pill-custom btn-outline-default m-2 flex-grow-1"
            [ngClass]="{'active': topico.ativo}" (click)="ativarSelecao(topico)"
            type="button">{{topico.titulo}}</button>

        </div>
        <div *ngIf="topicoSelecionado" class="conteudo-topico pt-3" [innerHTML]="topicoSelecionado.conteudo"> </div>
        <div *ngIf="linkDinamico && !beneficio?.conteudoFooter" class="beneficio-card-footer"><a [href]="linkDinamico"
            target="_blank" (click)="clickEmLinkDinamico(topico)"><strong>Baixe a CCT</strong></a></div>


      </div>

      <div *ngIf="beneficio?.conteudoFooterMobile" class="beneficio-card-footer w-100 pr-4 mt-3"
        [innerHTML]="beneficio?.conteudoFooterMobile"></div>

      <div class="beneficio-ilustracao-container d-flex justify-content-center">
        <img class="beneficio-ilustracao" [src]="ilustracaoUrlMobile">
      </div>
    </div>
  </div>
</div>
