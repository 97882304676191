<form [formGroup]="formMigrarPlano" novalidate>
	<div class="form-row">
		<div class="header d-flex justify-content-between col-md-12 mb-10">
			<h5 class="card-title">{{ 'vidas.modal_vida_avulsa.produtos' | translate }}</h5>
		</div>

		<div class="header d-flex justify-content-between col-md-12 mb-10">
			<h5 class="subtitle-card">{{ 'vidas.modal_vida_avulsa.odontologia' | translate }}</h5>
		</div>

		<div class="col">
			<div class="form-row mt-2">
				<div class="col">
					<agisales-input
						id="planoAtual"
						formControlName="planoAtual"
						label="Plano atual"
						[disabled]="true"
						ngDefaultControl>
					</agisales-input>
				</div>
			</div>
			<div class="form-row mt-4">
				<div class="col">
					<label class="form-label">{{ 'vidas.detail.planos_disponiveis' | translate }}*</label>
					<ng-select
						[multiple]="false"
						[closeOnSelect]="true"
						placeholder="{{ 'vidas.placeholders.selecione_opcao' | translate }}"
						formControlName="idNovoPlano"
						[hideSelected]="true"
						[ngClass]="{
							'is-invalid':
								formMigrarPlano.get('idNovoPlano').touched &&
								formMigrarPlano.get('idNovoPlano').errors?.required
						}">
						<ng-option *ngFor="let option of opcoesPlanoRadioGroup" [value]="option.value">
							{{ option.labelFormatado }}
						</ng-option>
					</ng-select>
				</div>
			</div>
		</div>
	</div>
	<div class="form-row mt-2">
		<div class="form-group col">
			<label class="form-label mb-1 font-weight-normal">
				* {{ 'vidas.detail.campo_obrigatorio' | translate }}
			</label>
		</div>
	</div>
</form>
