import {PlanoEmpresa} from 'src/app/model/interfaces/inclusao-vida-popup'
import {FileUploadPopup} from 'src/app/mfe/components/migrar-plano-popup/migrar-plano.interface'

export class DadosInclusaoVidas {
	tipoVida = ''
	isDependenteTitular = false
	empresaFieldDisabled = false
	idEmpresa = 0
	idEmpresaS4E = 0
	nomeEmpresa = ''
	idCompromisso = 0
	codAssociadoS4E: number
	idTitular: number
	nomeTitular: string
	cpfTitular: string
	nomeArquivo = ''
	urlFicha: string
	arquivosEnviados: FileUploadPopup[]
	listaProdutos: PlanoEmpresa[]
	proxVencimentoFaturaBeneficiario: string
}

export interface TipoVidaSelect {
	value: string
	descricao: string
}

export interface TitularSelect {
	value: number
	descricao: string
	cpf: string
	idEmpresaS4e: number
	idEmpresa: number
}

export interface EmpresaSelect {
	value: number
	descricao?: string
	label: string
	idEmpresaS4e: number
	idEmpresa: number
	altLabel: string
}

export interface DadosCpfResult {
	codigoStatus: number
	mensagemErro: string[]
	resultado: {
		chaveControle: string
		cpf: string
		signo: string
		nome: string
		ultimoNome: string
		sexo: string
		dataNascimento: string
		idade: number
		nomeMae: string
		situacaoReceita: string
		enderecos: any[]
		telefones: {
			ddd: string
			numero: string
			telefoneValido: number
			permissaoMarket: number
			assinante: boolean
			operadora: string
			tipo: number
		}[]
		emails: {
			email: string
		}[]
		participacaoEmpresa: {
			nome: string
			documento: string
			porcentagemParticipacao: string
			dataEntrada: string | null
		}[]
	}
}

export interface DadosImportadosBeneficiario {
	nome?: string
	nomeMae?: string
	dataNascimento?: Date | string
	sexo?: string | {descricao: string}
	telefone?: string
	telefonefixo?: string
	email?: string
}
