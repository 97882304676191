export enum InclusaoVidaPopupSteps {
	STEP_FORM_INCLUSAO_VIDA = 1,
	STEP_SELECAO_PRODUTO = 2,
	STEP_ENVIO_FOTO = 3,
	STEP_NOVA_INCLUSAO = 4,
	STEP_FORM_NOVA_INCLUSAO_VIDA = 5,
	STEP_NOVA_SELECAO_PRODUTO = 6,
	STEP_NOVA_ENVIO_FOTO = 7
}

export const InclusaoVidaPopupStepList = [
	InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA,
	InclusaoVidaPopupSteps.STEP_SELECAO_PRODUTO,
	InclusaoVidaPopupSteps.STEP_ENVIO_FOTO,
	InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO,
	InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA,
	InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO
]
